import { default as _91token_936I7CtATs9XMeta } from "/home/tsukamoto/public_html/workspace/nuxt3-client-srv/pages/activate/[token].vue?macro=true";
import { default as indexIGoejlfCPqMeta } from "/home/tsukamoto/public_html/workspace/nuxt3-client-srv/pages/apps/[userId]/books/index.vue?macro=true";
import { default as _91id_93LCEJ58myGnMeta } from "/home/tsukamoto/public_html/workspace/nuxt3-client-srv/pages/apps/[userId]/chat/[id].vue?macro=true";
import { default as indexxBYQA7wQ7GMeta } from "/home/tsukamoto/public_html/workspace/nuxt3-client-srv/pages/apps/[userId]/chat/index.vue?macro=true";
import { default as indexsxz8JwiV95Meta } from "/home/tsukamoto/public_html/workspace/nuxt3-client-srv/pages/apps/[userId]/dashboard/index.vue?macro=true";
import { default as indexgle9gLXzHMMeta } from "/home/tsukamoto/public_html/workspace/nuxt3-client-srv/pages/apps/[userId]/forbidden/index.vue?macro=true";
import { default as _91id_93RjOEFJyao8Meta } from "/home/tsukamoto/public_html/workspace/nuxt3-client-srv/pages/apps/[userId]/members/edit/[id].vue?macro=true";
import { default as indexTRld1AwQApMeta } from "/home/tsukamoto/public_html/workspace/nuxt3-client-srv/pages/apps/[userId]/members/index.vue?macro=true";
import { default as indexlDuQ9Bx6xLMeta } from "/home/tsukamoto/public_html/workspace/nuxt3-client-srv/pages/apps/[userId]/members/new/index.vue?macro=true";
import { default as indexCbPYv4dzC4Meta } from "/home/tsukamoto/public_html/workspace/nuxt3-client-srv/pages/apps/[userId]/plans/index.vue?macro=true";
import { default as indexOsGLGhwJ7nMeta } from "/home/tsukamoto/public_html/workspace/nuxt3-client-srv/pages/apps/[userId]/profile/index.vue?macro=true";
import { default as _91pid_93YVUOCZhVI2Meta } from "/home/tsukamoto/public_html/workspace/nuxt3-client-srv/pages/apps/[userId]/sengas/edit/[pid].vue?macro=true";
import { default as indexjxGZlPYwetMeta } from "/home/tsukamoto/public_html/workspace/nuxt3-client-srv/pages/apps/[userId]/sengas/index.vue?macro=true";
import { default as indextM1hMbdWPHMeta } from "/home/tsukamoto/public_html/workspace/nuxt3-client-srv/pages/apps/[userId]/sengas/new/index.vue?macro=true";
import { default as indexeny4m1W6pgMeta } from "/home/tsukamoto/public_html/workspace/nuxt3-client-srv/pages/apps/[userId]/setting/index.vue?macro=true";
import { default as indexaIvheAHpeMMeta } from "/home/tsukamoto/public_html/workspace/nuxt3-client-srv/pages/apps/[userId]/users/index.vue?macro=true";
import { default as indexR0gtSzqgtKMeta } from "/home/tsukamoto/public_html/workspace/nuxt3-client-srv/pages/index.vue?macro=true";
import { default as indexezo8eV3HffMeta } from "/home/tsukamoto/public_html/workspace/nuxt3-client-srv/pages/login/index.vue?macro=true";
export default [
  {
    name: _91token_936I7CtATs9XMeta?.name ?? "activate-token",
    path: _91token_936I7CtATs9XMeta?.path ?? "/activate/:token()",
    meta: _91token_936I7CtATs9XMeta || {},
    alias: _91token_936I7CtATs9XMeta?.alias || [],
    redirect: _91token_936I7CtATs9XMeta?.redirect || undefined,
    component: () => import("/home/tsukamoto/public_html/workspace/nuxt3-client-srv/pages/activate/[token].vue").then(m => m.default || m)
  },
  {
    name: indexIGoejlfCPqMeta?.name ?? "apps-userId-books",
    path: indexIGoejlfCPqMeta?.path ?? "/apps/:userId()/books",
    meta: indexIGoejlfCPqMeta || {},
    alias: indexIGoejlfCPqMeta?.alias || [],
    redirect: indexIGoejlfCPqMeta?.redirect || undefined,
    component: () => import("/home/tsukamoto/public_html/workspace/nuxt3-client-srv/pages/apps/[userId]/books/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93LCEJ58myGnMeta?.name ?? "apps-userId-chat-id",
    path: _91id_93LCEJ58myGnMeta?.path ?? "/apps/:userId()/chat/:id()",
    meta: _91id_93LCEJ58myGnMeta || {},
    alias: _91id_93LCEJ58myGnMeta?.alias || [],
    redirect: _91id_93LCEJ58myGnMeta?.redirect || undefined,
    component: () => import("/home/tsukamoto/public_html/workspace/nuxt3-client-srv/pages/apps/[userId]/chat/[id].vue").then(m => m.default || m)
  },
  {
    name: indexxBYQA7wQ7GMeta?.name ?? "apps-userId-chat",
    path: indexxBYQA7wQ7GMeta?.path ?? "/apps/:userId()/chat",
    meta: indexxBYQA7wQ7GMeta || {},
    alias: indexxBYQA7wQ7GMeta?.alias || [],
    redirect: indexxBYQA7wQ7GMeta?.redirect || undefined,
    component: () => import("/home/tsukamoto/public_html/workspace/nuxt3-client-srv/pages/apps/[userId]/chat/index.vue").then(m => m.default || m)
  },
  {
    name: indexsxz8JwiV95Meta?.name ?? "apps-userId-dashboard",
    path: indexsxz8JwiV95Meta?.path ?? "/apps/:userId()/dashboard",
    meta: indexsxz8JwiV95Meta || {},
    alias: indexsxz8JwiV95Meta?.alias || [],
    redirect: indexsxz8JwiV95Meta?.redirect || undefined,
    component: () => import("/home/tsukamoto/public_html/workspace/nuxt3-client-srv/pages/apps/[userId]/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexgle9gLXzHMMeta?.name ?? "apps-userId-forbidden",
    path: indexgle9gLXzHMMeta?.path ?? "/apps/:userId()/forbidden",
    meta: indexgle9gLXzHMMeta || {},
    alias: indexgle9gLXzHMMeta?.alias || [],
    redirect: indexgle9gLXzHMMeta?.redirect || undefined,
    component: () => import("/home/tsukamoto/public_html/workspace/nuxt3-client-srv/pages/apps/[userId]/forbidden/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93RjOEFJyao8Meta?.name ?? "apps-userId-members-edit-id",
    path: _91id_93RjOEFJyao8Meta?.path ?? "/apps/:userId()/members/edit/:id()",
    meta: _91id_93RjOEFJyao8Meta || {},
    alias: _91id_93RjOEFJyao8Meta?.alias || [],
    redirect: _91id_93RjOEFJyao8Meta?.redirect || undefined,
    component: () => import("/home/tsukamoto/public_html/workspace/nuxt3-client-srv/pages/apps/[userId]/members/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: indexTRld1AwQApMeta?.name ?? "apps-userId-members",
    path: indexTRld1AwQApMeta?.path ?? "/apps/:userId()/members",
    meta: indexTRld1AwQApMeta || {},
    alias: indexTRld1AwQApMeta?.alias || [],
    redirect: indexTRld1AwQApMeta?.redirect || undefined,
    component: () => import("/home/tsukamoto/public_html/workspace/nuxt3-client-srv/pages/apps/[userId]/members/index.vue").then(m => m.default || m)
  },
  {
    name: indexlDuQ9Bx6xLMeta?.name ?? "apps-userId-members-new",
    path: indexlDuQ9Bx6xLMeta?.path ?? "/apps/:userId()/members/new",
    meta: indexlDuQ9Bx6xLMeta || {},
    alias: indexlDuQ9Bx6xLMeta?.alias || [],
    redirect: indexlDuQ9Bx6xLMeta?.redirect || undefined,
    component: () => import("/home/tsukamoto/public_html/workspace/nuxt3-client-srv/pages/apps/[userId]/members/new/index.vue").then(m => m.default || m)
  },
  {
    name: indexCbPYv4dzC4Meta?.name ?? "apps-userId-plans",
    path: indexCbPYv4dzC4Meta?.path ?? "/apps/:userId()/plans",
    meta: indexCbPYv4dzC4Meta || {},
    alias: indexCbPYv4dzC4Meta?.alias || [],
    redirect: indexCbPYv4dzC4Meta?.redirect || undefined,
    component: () => import("/home/tsukamoto/public_html/workspace/nuxt3-client-srv/pages/apps/[userId]/plans/index.vue").then(m => m.default || m)
  },
  {
    name: indexOsGLGhwJ7nMeta?.name ?? "apps-userId-profile",
    path: indexOsGLGhwJ7nMeta?.path ?? "/apps/:userId()/profile",
    meta: indexOsGLGhwJ7nMeta || {},
    alias: indexOsGLGhwJ7nMeta?.alias || [],
    redirect: indexOsGLGhwJ7nMeta?.redirect || undefined,
    component: () => import("/home/tsukamoto/public_html/workspace/nuxt3-client-srv/pages/apps/[userId]/profile/index.vue").then(m => m.default || m)
  },
  {
    name: _91pid_93YVUOCZhVI2Meta?.name ?? "apps-userId-sengas-edit-pid",
    path: _91pid_93YVUOCZhVI2Meta?.path ?? "/apps/:userId()/sengas/edit/:pid()",
    meta: _91pid_93YVUOCZhVI2Meta || {},
    alias: _91pid_93YVUOCZhVI2Meta?.alias || [],
    redirect: _91pid_93YVUOCZhVI2Meta?.redirect || undefined,
    component: () => import("/home/tsukamoto/public_html/workspace/nuxt3-client-srv/pages/apps/[userId]/sengas/edit/[pid].vue").then(m => m.default || m)
  },
  {
    name: indexjxGZlPYwetMeta?.name ?? "apps-userId-sengas",
    path: indexjxGZlPYwetMeta?.path ?? "/apps/:userId()/sengas",
    meta: indexjxGZlPYwetMeta || {},
    alias: indexjxGZlPYwetMeta?.alias || [],
    redirect: indexjxGZlPYwetMeta?.redirect || undefined,
    component: () => import("/home/tsukamoto/public_html/workspace/nuxt3-client-srv/pages/apps/[userId]/sengas/index.vue").then(m => m.default || m)
  },
  {
    name: indextM1hMbdWPHMeta?.name ?? "apps-userId-sengas-new",
    path: indextM1hMbdWPHMeta?.path ?? "/apps/:userId()/sengas/new",
    meta: indextM1hMbdWPHMeta || {},
    alias: indextM1hMbdWPHMeta?.alias || [],
    redirect: indextM1hMbdWPHMeta?.redirect || undefined,
    component: () => import("/home/tsukamoto/public_html/workspace/nuxt3-client-srv/pages/apps/[userId]/sengas/new/index.vue").then(m => m.default || m)
  },
  {
    name: indexeny4m1W6pgMeta?.name ?? "apps-userId-setting",
    path: indexeny4m1W6pgMeta?.path ?? "/apps/:userId()/setting",
    meta: indexeny4m1W6pgMeta || {},
    alias: indexeny4m1W6pgMeta?.alias || [],
    redirect: indexeny4m1W6pgMeta?.redirect || undefined,
    component: () => import("/home/tsukamoto/public_html/workspace/nuxt3-client-srv/pages/apps/[userId]/setting/index.vue").then(m => m.default || m)
  },
  {
    name: indexaIvheAHpeMMeta?.name ?? "apps-userId-users",
    path: indexaIvheAHpeMMeta?.path ?? "/apps/:userId()/users",
    meta: indexaIvheAHpeMMeta || {},
    alias: indexaIvheAHpeMMeta?.alias || [],
    redirect: indexaIvheAHpeMMeta?.redirect || undefined,
    component: () => import("/home/tsukamoto/public_html/workspace/nuxt3-client-srv/pages/apps/[userId]/users/index.vue").then(m => m.default || m)
  },
  {
    name: indexR0gtSzqgtKMeta?.name ?? "index",
    path: indexR0gtSzqgtKMeta?.path ?? "/",
    meta: indexR0gtSzqgtKMeta || {},
    alias: indexR0gtSzqgtKMeta?.alias || [],
    redirect: indexR0gtSzqgtKMeta?.redirect || undefined,
    component: () => import("/home/tsukamoto/public_html/workspace/nuxt3-client-srv/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexezo8eV3HffMeta?.name ?? "login",
    path: indexezo8eV3HffMeta?.path ?? "/login",
    meta: indexezo8eV3HffMeta || {},
    alias: indexezo8eV3HffMeta?.alias || [],
    redirect: indexezo8eV3HffMeta?.redirect || undefined,
    component: () => import("/home/tsukamoto/public_html/workspace/nuxt3-client-srv/pages/login/index.vue").then(m => m.default || m)
  }
]